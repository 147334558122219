import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import Cta from "../components/cta"
import { useStaticQuery, graphql  } from "gatsby";

import "../styles/object/component/typography.scss"
import "../styles/object/project/about.scss"
import define from "../config/define";


const query = graphql`
  query {
    visionImg: file(relativePath: { eq: "about_01.svg" }) {
      publicURL
    }
    originImg: file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
    messageImg: file(relativePath: { eq: "about_02.png" }) {
      publicURL
    }
  }
`

export default () => {
    const data = useStaticQuery(query)

    return (
       <Layout title="About">
        <Title top="ABOUT" topSub="私たちについて"/>
        <main className="l-main">
            <section className="vision">
                <div className="c-heading">
                    <h4 className="c-heading_main">VISION</h4>
                    <h5 className="c-heading_sub">経営理念</h5>
                </div>
                <div className="container">
                    <div className="">
                        <div className="v-title list-mark">誠実さ</div>
                        <p className="v-content">対等な立場による信頼関係を構築する。法令遵守。</p>
                        {/*現代において「誠実さ」を維持することは最も困難な課題であろうと思います。*/}

                    </div>
                    <div>
                        <div className="v-title list-mark">想像・創造力</div>
                        <p className="v-content">相手の視点に立った想像をし、さらなる課題を克服する創造力を身につける。</p>
                    </div>
                    <div>
                        <div className="v-title list-mark">好奇心</div>
                        <p className="v-content">常に新しいものを求める探究心。議論よりも証明する力。</p>
                    </div>
                    <img src={data.visionImg.publicURL} alt="イメージ画像" className="vision_img" />
                </div>
            </section>
            <section className="precept">
                <div className="c-heading">
                    <h4 className="c-heading_main">PRECEPT</h4>
                    <h5 className="c-heading_sub">行動指針</h5>
                </div>
                <div className="container">
                    <p className="list-mark">エンジニア、クリエーターが健やかに働ける環境を作る。</p>
                    <p className="list-mark">オモシロイこと見つけたらを共有する。</p>
                    <p className="list-mark">学び続ける。</p>
                </div>
            </section>
            <section className="origin">
                <div className="c-heading">
                    <h4 className="c-heading_main">ORIGIN</h4>
                    <h5 className="c-heading_sub">由来</h5>
                </div>
                <div className="container">
                    <div className="flexbox">
                        <figure>
                            <img src={data.originImg.publicURL} alt="Plmin.usロゴ" className="" />
                        </figure>
                        <p>私たちは、普遍的に継続して進化し続ける事を会社の第一目的として考えています。『株式会社プラミナス（Plmin.us）』は、遺伝子による進化がまさにそうあるように、時代の変化に合わせ必要なものを常に創造・改良し続け、無駄を省くことをおそれないことを示すため、プラス（Plus）とマイナス（Minus）を掛け合わせた社名としました。
                        </p>
                    </div>
                </div>
            </section>

            <section className="message">
                <div className="c-heading">
                    <h4 className="c-heading_main">MESSAGE</h4>
                    <h5 className="c-heading_sub">プラミナスの始まり</h5>
                </div>
                <div className="container">
                    <p>
                        宮崎県出身。農業高校卒。
                        子供の頃からTVゲーム好きが講じ、将来エンジニアになることを夢見て、住み込みで新聞配達をしながらゲームプログラミングの専門学校を卒業しました。
                        卒業と時同じくしてITバブル崩壊で就職氷河期が始まり、とにかく就職することを第一に都内のシステム会社に入社しました。
                        初めは短期間でのシステム開発が主でしたが、ある時出向先の案件を一人で要件定義・設計・開発・検証・納品を任せられ大きな自信と経験に繋がります。
                        もっと色んなことに挑戦したいと、その後3社のITベンチャーに転職を経て、2012年(33歳)に独立し「プラミナス」創業しました。
                        その際、かつての同僚から声を掛けられ共同で案件をこなすようになりました。当時住んでいたシェアハウスで金融系のシステム会社を紹介され、常駐での開発をスタートします。
                        基盤が安定してきた事をきっかけに、2016年に「株式会社プラミナス」へと法人成りを果たしました。
                    </p>
                </div>
                <div className="c-heading">
                    <h5 className="c-heading_sub">代表挨拶</h5>
                </div>
                <div className="container">
                    <div className="flexbox">
                        <p>これまで、大手ビジネス情報サービスや金融系のシステム開発をメインに事業を展開しIT技術の成長とともに私たちも成長してきました。「好奇心を持ち続けオモシロいサービスをカタチに」という思いで、私たちの作るシステムが、楽しい未来へつながっていくよう日々邁進していく所存です。また、ライフステージのシームレス化を目標に、より一層働きやすい組織となり、多くのエンジニア・デザイナーが活躍できる会社を目指します。今後ともますますのご支援とご愛好を賜りますようお願い申し上げます。</p>
                        <figure>
                            <img src={data.messageImg.publicURL} alt={`代表取締役　${define.corp.ceo}`} className="" />
                            <figcaption>代表取締役 {define.corp.ceo}</figcaption>
                        </figure>
                    </div>
                </div>
            </section>
            <Cta/>
        </main>
    </Layout>
    )
}